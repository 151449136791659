$monova-orange: #d95427;
$monova-blue: #098aaa;
$monova-dark-blue: #343c4d;
$monova-beige: #e7e5d9;
/*$gray-200: #f0efe7;
$gray-300: #eceae0;
$gray-400: #cacabe;*/
$primary: $monova-orange;
$secondary: $monova-dark-blue;
$dark: $monova-dark-blue;
$tooltip-bg: $monova-dark-blue;
$link-color: $monova-blue;
$navbar-dark-color: $monova-blue;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$monova-beige}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$font-family-base: Arial, Helvetica, sans-serif;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #198754,
  "info": #0dcaf0,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": $dark,
  "monova-orange": $monova-orange,
  "monova-blue": $monova-blue,
  "monova-dark-blue": $monova-dark-blue,
  "monova-beige": $monova-beige,
);

@import "../../arDominionB5Plugin/scss/main.scss";

header {
  .navbar-brand img {
    max-height: 55px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
      position: relative;
      bottom: -36px;
    }
  }

  .atom-btn-secondary {
    background-color: $monova-blue;
    border-color: $monova-blue;
  }
}

/* Hide bottom margin from alerts in homepage */
.alert + #monova-homepage-heading {
  margin-top: -$spacer;
}

.monova-container {
  @extend .container;
  max-width: 960px;

  > p {
    margin-bottom: 0;
  }
}

strong {
  font-weight: 600;
}

.btn-monova-blue {
  @include button-variant($monova-blue, $monova-blue);
  color: $monova-beige;
  border-radius: 0;

  &:hover,
  &:focus {
    color: $monova-beige;
  }
}

.btn-monova-dark-blue {
  @include button-variant($monova-dark-blue, $monova-dark-blue);
  color: $monova-beige;
  border-radius: 0;

  &:hover,
  &:focus {
    color: $monova-beige;
  }
}

#monova-homepage-heading {
  background: linear-gradient(
      rgba($monova-beige, 0.8),
      rgba($monova-beige, 0.8)
    ),
    url(../images/monova-homepage-heading.jpg);
  background-size: cover;
}

#monova-carousel {
  background-color: $dark;

  img {
    display: inline-block;
    max-width: 100%;
  }

  #monova-slider-title {
    height: calc(100% - 30px);

    .slick-list {
      margin: 0 (-$spacer * 1.5);
    }

    .slick-arrow {
      top: calc(100% + 15px);
    }

    .slick-autoplay-toggle-button {
      color: $white;
      top: calc(100% + 3px);
    }
  }
}

#monova-homepage-bottom {
  background-image: url(../images/monova-homepage-bottom.jpg);
  background-size: cover;
  height: 440px;
}

footer {
  a,
  a:hover,
  a:focus {
    color: $monova-beige;
  }
}
